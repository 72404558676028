<template>
  <v-card>
    <v-stepper v-model="step" vertical>
      <v-stepper-step :complete="step > 1" step="1">
        {{ $t("tag.createSteps.step1") }}
      </v-stepper-step>
      <v-stepper-items>
        <v-stepper-content step="1">
          <device-type-select v-model="type"></device-type-select>
          <v-form
            :lazy-validation="true"
            @submit.prevent="handleSubmit"
            v-model="valid"
            ref="form"
          >
            <v-text-field
              :label="$t('tag.fields.name')"
              name="name"
              prepend-icon="mdi-form-textbox"
              type="text"
              v-model="name"
              :rules="nameRules"
              class="required"
            ></v-text-field>

            <div class="d-flex justify-space-between">
              <byte-input
                :label="$t('tag.fields.deveui')"
                v-model="deveui"
                class="required"
                v-if="type == 0"
              />

              <v-text-field
                v-else
                :label="$t('tag.fields.id')"
                name="did"
                prepend-icon="mdi-fingerprint"
                type="text"
                v-model="deveui"
                :rules="idRules"
                :error-messages="deveuiError"
                class="required"
              ></v-text-field>

              <v-btn
                text
                color="warning"
                class="align-self-center"
                @click="generateDeveui"
                v-if="type != 0"
                >{{ $t("tag.generateId") }}</v-btn
              >
            </div>
          </v-form>

          <v-row v-if="type == 0">
            <tag-csv-upload v-model="csvUploadedTags" />
            <v-container fluid>
              <v-row
                v-for="(d, i) of csvUploadedTags"
                :key="i"
                class="node-row"
              >
                <v-col cols="6">
                  <v-text-field
                    :label="$t('tag.fields.name')"
                    prepend-icon="mdi-form-textbox"
                    v-model="d.name"
                    class="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <byte-input
                    :label="$t('tag.fields.deveui')"
                    v-model="d.deveui"
                    class="required"
                  />
                </v-col>
                <v-col cols="6">
                  <byte-input
                    :label="$t('tag.fields.appKey')"
                    prepend-icon="mdi-key-link"
                    v-model="d.appKey"
                    :bytes="16"
                    :required="false"
                  />
                </v-col>
                <v-col cols="6">
                  <byte-input
                    :label="$t('tag.fields.appEui')"
                    prepend-icon="mdi-key-link"
                    v-model="d.appEui"
                    :bytes="8"
                    :required="false"
                  />
                </v-col>
                <v-col cols="6">
                  <DecoderSelect v-model="d.decoder" />
                </v-col>

                <v-col cols="4">
                  <v-switch
                    name="classC"
                    prepend-icon="mdi-alpha-c-circle-outline"
                    v-model="d.classC"
                    label="Class C Device"
                  ></v-switch>
                </v-col>

                <v-col cols="2">
                  <v-btn icon fab @click="removeCsvTag(i)" color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="moveStep"
              x-large
              :disabled="!canMoveStep"
            >
              {{ $t("common.next") }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-step :complete="step > 2" step="2">
          {{ $t("tag.createSteps.step2") }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <!-- Display selectionsheets for all the applications and a extra selectionsheet to create a new application -->
          <application-selector v-model="applicationId"></application-selector>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="moveBackStep" x-large>
              <v-icon left>mdi-arrow-left</v-icon>
              {{ $t("common.previousStep") }}
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="moveStep"
              x-large
              :disabled="!canMoveStep"
            >
              {{ $t("common.next") }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-step :complete="step > 3" step="3" v-if="type == 0">
        {{ $t("tag.createSteps.step3") }}
      </v-stepper-step>

      <v-stepper-content step="3" v-if="type == 0">
        <v-container>
          <DecoderSelect :advanced="true" v-model="decoder" />

          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="moveBackStep" x-large>
              <v-icon left>mdi-arrow-left</v-icon>
              {{ $t("common.previousStep") }}
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="moveStep"
              x-large
              :disabled="!canMoveStep"
            >
              {{ $t("common.next") }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4" v-if="type == 0">
        {{ $t("tag.createSteps.step4") }}
      </v-stepper-step>

      <v-stepper-content step="4" v-if="type == 0">
        <v-card-text>
          <byte-input
            :label="$t('tag.fields.appKey')"
            v-model="appKey"
            icon="mdi-key-link"
            :bytes="16"
            :required="false"
          />
        </v-card-text>

        <v-card-text>
          <byte-input
            :label="$t('tag.fields.appEui')"
            v-model="appEui"
            icon="mdi-key-link"
            :bytes="8"
            :required="false"
          />
        </v-card-text>

        <v-switch
          name="classC"
          prepend-icon="mdi-alpha-c-circle-outline"
          v-model="classC"
          label="Class C Device"
        ></v-switch>

        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="step >= 5" step="5">
        {{ $t("tag.createSteps.step5") }}
      </v-stepper-step>

      <v-stepper-content step="5">
        <broker-selector v-model="mqttMeta.brokerId" />
        <mqtt-meta-form
          v-model="mqttMeta"
          :broker="mqttMeta.brokerId"
          :deveui="deveui"
          :node-type="type"
        />
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="step >= 6" step="6">
        {{ $t("tag.createSteps.finish") }}
      </v-stepper-step>

      <v-stepper-content step="6">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>
        </v-row>
      </v-stepper-content>
    </v-stepper>

    <v-card-actions>
      <v-btn
        color="primary"
        type="button"
        @click="handleSubmit"
        x-large
        :disabled="!canSave"
        >{{ $t("common.create") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ApplicationSelector from "@/components/application/ApplicationSelector.vue";
import BrokerSelector from "@/components/mqtt/BrokerSelector";
import ByteInput from "../common/ByteInput.vue";
import crypto from "crypto";
import DecoderSelect from "@/components/tag/DecoderSelect.vue";
import DeviceTypeSelect from "@/components/tag/DeviceTypeSelect";
import i18n from "@/plugins/i18n";
import MqttMetaForm from "@/components/mqtt/MqttMetaForm";
import TagCsvUpload from "@/components/tag/TagCsvUpload";

export default {
  name: "TagCreate",
  components: {
    ApplicationSelector,
    BrokerSelector,
    ByteInput,
    DecoderSelect,
    DeviceTypeSelect,
    MqttMetaForm,
    TagCsvUpload,
  },

  data() {
    return {
      submitted: false,
      step: 1,
      valid: false,
      deveui: "",
      appKey: "",
      appEui: "",
      name: "",
      latitude: null,
      longitude: null,
      applicationId: null,
      classC: false,
      decoder: "",
      deveuiError: "",
      type: 0,
      mqttMeta: {
        brokerId: null,
        publishTopic: "",
        topics: [],
      },
      csvUploadedTags: [],
      nameRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.name")]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [this.$t("tag.fields.name"), 255]),
      ],
      deveuiRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.deveui")]),
        (v) =>
          (v && v.length <= 16) ||
          this.$t("validation.max", [this.$t("tag.fields.deveui"), 16]),
      ],
      idRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.deveui")]),
        (v) =>
          (v && v.length <= 75) ||
          this.$t("validation.max", [this.$t("tag.fields.deveui"), 75]),
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),

    ...mapState("companies", {
      companyApps: "companyApps",
      appsLoad: "status.loading",
    }),

    ...mapState("tag", ["apiErrors"]),

    canMoveStep() {
      if (this.type == 0)
        switch (this.step) {
          case 1:
            if (
              this.deveui != "" &&
              this.deveui &&
              this.name != "" &&
              this.name &&
              this.csvUploadedTags.length == 0 &&
              this.$refs.form.validate()
            )
              return true;

            if (this.csvUploadedTags.length > 0) return true;
            return false;
          case 2:
            return this.applicationId != "" && this.applicationId;
          default:
            return true;
        }

      if (this.type == 1)
        switch (this.step) {
          case 1:
            if (
              this.deveui != "" &&
              this.deveui &&
              this.name != "" &&
              this.name
            )
              return true;
            return false;
          case 2:
            return this.applicationId != "" && this.applicationId;
          default:
            return true;
        }

      if (this.type == 2)
        switch (this.step) {
          case 1:
            if (
              this.deveui != "" &&
              this.deveui &&
              this.name != "" &&
              this.name
            )
              return true;
            return false;
          case 2:
            return this.applicationId != "" && this.applicationId;
          default:
            return true;
        }

      return false;
    },

    canSave() {
      // IF LORAWAN
      if (this.type == 0) return this.step >= 6;
      // IF API
      if (this.type == 1) return this.step >= 6;
      // IF MQTT
      if (this.type == 2) return this.step >= 6;

      return false;
    },
  },
  methods: {
    ...mapActions("tag", ["createTag"]),
    ...mapActions("companies", ["getCompanyApplications"]),
    ...mapActions("mqtt", ["createMqttMeta"]),

    removeCsvTag(index) {
      this.csvUploadedTags.splice(index, 1);
    },

    moveStep() {
      if (!this.canMoveStep) return;

      switch (this.type) {
        case 0:
          if (this.step == 2 && this.csvUploadedTags.length > 0) this.step += 4;
          else this.step++;
          break;
        case 1:
          if (this.step == 2) this.step += 3;
          else this.step++;
          break;
        case 2:
          if (this.step == 2) this.step += 3;
          else this.step++;
          break;
      }
    },

    moveBackStep() {
      switch (this.type) {
        case 0:
          if (this.step == 5 && this.csvUploadedTags.length > 0) this.step -= 3;
          else this.step--;
          break;
        case 1:
          if (this.step == 4) this.step -= 2;
          else if (this.step == 5) this.step -= 3;
          else this.step--;
          break;
        case 2:
          if (this.step >= 5) this.step = 2;
          else this.step--;
      }
    },

    parseErrors() {
      // Clear old validation errors
      this.deveuiError = "";
      // Check for new validation errors, apiErrors have capitalized keys
      for (let error in this.apiErrors) {
        if (error === "Deveui") {
          this.deveuiError = i18n.t("validation.deveuiConflict");
        }
      }
    },

    generateDeveui() {
      this.deveui = crypto.randomBytes(8).toString("hex");
    },

    async handleSubmit() {
      this.submitted = true;

      if (this.csvUploadedTags.length <= 0) {
        const {
          deveui,
          name,
          profile,
          latitude,
          longitude,
          appKey,
          appEui,
          decoder,
          classC,
          type,
        } = this;

        let payload = {
          deveui,
          name,
          profile,
          latitude,
          longitude,
          appKey,
          appEui,
          decoder,
          classC,
          nodeType: type,
        };

        await this.uploadNode(payload);

        // Upload TagMeta
        if (this.mqttMeta.brokerId != null) {
          await this.createMqttMeta({
            brokerId: this.mqttMeta.brokerId,
            deveui: this.deveui,
            payload: this.mqttMeta,
          });
        }
      } else {
        for (let tag of this.csvUploadedTags) {
          let payload = {
            deveui: tag.deveui,
            appKey: tag.appKey,
            appEui: tag.appEui,
            name: tag.name,
            decoder: tag.decoder,
            profile: this.profile,
            classC: tag.classC,
          };

          await this.uploadNode(payload);
        }
      }

      this.$router.push("/tag");
    },

    async uploadNode(payload) {
      this.submitted = true;
      if (payload.appKey != "" && payload.appKey)
        payload.appKey = payload.appKey.replace(/ /g, "").trim();
      if (payload.appEui != "" && payload.appEui)
        payload.appEui = payload.appEui.replace(/ /g, "").trim();

      payload.nodeType = this.type;
      return await this.createTag({
        applicationId: this.applicationId,
        payload: payload,
      }).then(() => {
        if (this.apiErrors.length >= 0) {
          return this.parseErrors();
        }
      });
    },
  },

  created() {
    if (!this.hasCompany) this.$router.push("/company/create");
    if (this.$route.params.deveui) this.deveui = this.$route.params.deveui;
  },
};
</script>

<style>
.required label::after {
  content: " *";
}
.node-row {
  border-radius: 10px;
  border: 1px solid rgb(216, 216, 216);
  color: black;
  max-width: 100%;
  margin: 0;
  margin-top: 1rem;
}
</style>