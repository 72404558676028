<template>
  <v-container fluid>
    <v-container
      class="pa-6 gray-hover drop-box"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        id="fileInput"
        name="file"
        ref="file"
        accept=".csv"
        class="hidden-file-input"
        @change="onChange"
      />
      <label for="fileInput">
        <div>
          {{ $t("tag.uploadExplanation") }}
        </div>
        <small class="small-hint">
          {{ $t("tag.uploadExplanationHint") }}

          <a href="/example.csv" target="_blank" rel="noopener noreferrer">{{
            $t("tag.exampleLink")
          }}</a>
        </small>
      </label>
      <div style="width: 100%; text-align: center; color: red" v-if="error">
        {{ this.error }}
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "TagCsvUpload",

  props: {
    mdl: {
      default: function () {
        return [];
      },
    },
  },

  model: {
    prop: "mdl",
  },

  data() {
    return {
      file: null,
      items: [],
      stringResult: "",
      error: null,
    };
  },

  methods: {
    onChange() {
      this.error = null;
      this.file = this.$refs.file.files[0];

      // Reader
      let reader = new FileReader();
      reader.onload = (res) => {
        let stringResult = res.target.result;
        let sArr = stringResult.split(/\r?\n/);
        if (sArr < 2) return;

        let tags = [];
        for (var l of sArr.slice(1, sArr.length)) {
          let parts = l.split(",");
          if (parts.length < 5) continue;

          tags.push({
            name: parts[0],
            deveui: parts[1],
            appKey: parts[2],
            appEui: parts[3],
            classC: parts[4] == "True" || parts[4] == "true" ? true : false,
            decoder: "raw",
          });
        }

        if (tags.length > 0) {
          this.$emit("input", tags);
        }
      };
      reader.onerror = () =>
        (this.error = "Something went wrong when trying to upload the file.");
      reader.readAsText(this.file);
    },

    dragover(event) {
      event.preventDefault();

      if (!event.currentTarget.classList.contains("green-hover")) {
        event.currentTarget.classList.remove("gray-hover");
        event.currentTarget.classList.add("green-hover");
      }
    },

    dragleave(event) {
      this.cleanUpHover(event);
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      this.cleanUpHover(event);
    },

    cleanUpHover(event) {
      event.currentTarget.classList.add("gray-hover");
      event.currentTarget.classList.remove("green-hover");
    },
  },

  computed: {
    tags() {
      if (this.items.length <= 0) return [];

      return this.items;
    },
  },
};
</script>

<style scoped>
.hidden-file-input {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.drop-box {
  color: white;
  font-size: 1rem;
  letter-spacing: 0.1666666667em !important;
  line-height: 1.5rem;
  font-family: Roboto, sans-serif !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border: 1px solid rgb(175, 175, 175);
}

.green-hover {
  background: #17d7a0;
}

.gray-hover {
  background: #f2f2f2;
  color: black;
}

.small-hint {
  opacity: 0.7;
  font-size: 0.7rem;
}
</style>